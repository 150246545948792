import { Alert, Box, Button, TextField } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import { QrReader } from 'react-qr-reader';
import Image from './Image';
import Iconify from './Iconify';
import useLocales from '../hooks/useLocales';

const QRCodeReader = ({ onScan = () => {}, onError = () => {}, ...others }) => {
  const { translate } = useLocales();

  const [devices, setDevices] = useState([]);

  const [selectedDeviceId, setSelectedDeviceId] = useState('');

  const [error, setError] = useState('');

  const [hasCameraAccess, setHasCameraAccess] = useState(true);

  const [permissionDenied, setPermissionDenied] = useState(false);

  const [currentDeviceIndex, setCurrentDeviceIndex] = useState(1);

  const streamRef = useRef();

  /**
   * Special case for this project, qrcode will always return url pattern https://url.com?aff=123
   * @returns String
   */
  const getAffCode = (url) => {
    if (!url) return '';
    const urlObj = new URL(url);
    const params = new URLSearchParams(urlObj.search);
    return params.get('aff');
  };

  const getDevices = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      streamRef.current = stream;

      setPermissionDenied(false);

      // Check if mediaDevices and getUserMedia are available
      if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        setHasCameraAccess(false);
        return;
      }

      // Get the list of video input devices (cameras)
      navigator.mediaDevices.enumerateDevices().then((deviceInfos) => {
        const videoDevices = deviceInfos.filter((device) => device.kind === 'videoinput');
        setDevices(videoDevices);
        // if (videoDevices.length > 0) {
        //   setSelectedDeviceId(videoDevices[0].deviceId);
        // }
      });
    } catch (err) {
      if (err.name === 'NotAllowedError' || err.name === 'SecurityError') {
        setPermissionDenied(true);
      } else {
        console.error('Error accessing media devices.', err);
        setHasCameraAccess(false);
      }
    }
  };

  useEffect(() => {
    getDevices();

    return () => {
      setSelectedDeviceId('');
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
        streamRef.current = null;
      }
    };
  }, []);

  const handleScan = (data) => {
    const affCode = getAffCode(data);
    if (affCode) {
      onScan(affCode);
    }
  };

  const handleError = (err) => {
    console.error(err);

    setError(err?.message);

    onError(err?.message);
  };

  const handleCycleDevice = () => {
    if (devices.length === 0) return;

    const nextIndex = (currentDeviceIndex + 1) % devices.length;
    setCurrentDeviceIndex(nextIndex);
    setSelectedDeviceId(devices[nextIndex].deviceId);
  };

  if (permissionDenied) {
    return (
      <Alert severity="error" sx={{ m: 2 }}>
        {translate('camera_access_denied')}
      </Alert>
    );
  }

  if (!hasCameraAccess) {
    return (
      <Alert severity="error" sx={{ m: 2 }}>
        {translate('camera_not_available')}
      </Alert>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        // justifyContent: 'center',
        flexDirection: 'column',
        gap: 1,
        pt: 7,
        height: '100%',
      }}
      {...others}
    >
      {/* {error && (
        <Alert severity="error" sx={{ m: 2 }}>
          {error}
        </Alert>
      )} */}
      {/* {selectedDeviceId && ( */}
      <Box sx={{ height: 'auto', width: '100%', position: 'relative' }}>
        <QrReader
          key={selectedDeviceId}
          onResult={(result, error) => {
            if (result) {
              handleScan(result?.text);
            }

            if (error?.message) {
              handleError(error);
            }
          }}
          containerStyle={{ height: 'auto', width: '100%' }}
          videoContainerStyle={{ paddingTop: 'unset' }}
          videoStyle={{ height: '100%', width: '100%', position: 'unset' }}
          constraints={{ deviceId: selectedDeviceId, facingMode: 'environment' }}
        />
        <Image
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            width: { xs: '80%', md: '60%' },
            height: 'auto',
            transform: 'translate(-50%, -50%)',
            boxSizing: 'border-box',
            pointerEvents: 'none',
            opacity: 0.5,
          }}
          src={require('../assets/scan-outline.png')}
        />
      </Box>
      {/* )} */}

      <Box sx={{ p: 2, display: 'flex', justifyContent: 'center' }}>
        <Button variant="contained" onClick={handleCycleDevice}>
          <Iconify icon="teenyicons:refresh-solid" sx={{ fontSize: '25px' }} />
        </Button>
      </Box>

      {/* <TextField
        fullWidth
        size="small"
        select
        SelectProps={{ native: true }}
        onChange={handleDeviceChange}
        value={selectedDeviceId}
        sx={{ p: 2 }}
      >
        {devices.map((device, index) => (
          <option key={index} value={device.deviceId}>
            {device.label || `Camera ${index + 1}`}
          </option>
        ))}
      </TextField> */}
    </Box>
  );
};

export default QRCodeReader;
