// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
  login: `Login`,
  logout: `Logout`,
  register: `Register`,
  already_have_account: `Already have an account?`,
  my_profile: `My Profile`,
  basic_information: `Basic Information`,
  edit: `Edit`,
  user_id: `User ID`,
  username: `Username`,
  name: 'Name',
  phone_number: 'Phonenumber',
  confirm_password: 'Confirm Password',
  password_must_match: 'Password must match',
  account_type: `Account Type`,
  status: `Status`,
  currency: `Currency`,
  created_time: `Created Time`,
  last_login: `Last Login`,
  prefix: `Prefix`,
  wallet_type: `Wallet Type`,
  product_information: `Product Information`,
  platform: `Platform`,
  supported_currency: `Supported Currency`,
  my_pt_allow: `My Pt Allow`,
  bet_limit: `Bet Limit`,
  table: `Table`,
  virtual: `Virtual`,
  member_tree: `Member Tree`,
  real: `Real`,
  test: `Test`,
  active: `Active`,
  suspended: `Suspended`,
  locked: `Locked`,
  submit: `Submit`,
  export: `Export`,
  view: `View`,
  login_failure: `Login Failure`,
  password_lock: `Password Lock`,
  input_x: `Input {{x}}`,
  sub_account: `Sub Account`,
  add_sub_account: `Add Sub Account`,
  win_loss_summary: `Win / Loss Summary`,
  date_time: `Date and Time`,
  time_zone: `Time Zone`,
  type: `Type`,
  location: `Location`,
  last_month: `Last Month`,
  this_month: `This Month`,
  last_week: `Last Week`,
  this_week: `This Week`,
  yesterday: `Yesterday`,
  today: `Today`,
  view_all: `View All`,
  display_columns: `Display Columns`,
  summary: `Summary`,
  valid_turnover: `Valid Turnover`,
  total_bet: `Total Bet`,
  bet_amount: `Bet Amount`,
  player: `Player`,
  player_win_loss: `Player Win / Loss`,
  adjustment: `Adjustment`,
  total_pl: `Total P/L`,
  margin: `Margin`,
  pt_win_loss: `PT Win/Loss`,
  agent: `Agent`,
  company: `Company`,
  grand_total_x: `Grand Total ({{x}})`,
  start_date: `Start Date`,
  end_date: `End Date`,
  clear: `Clear`,
  bet_count: `Bet Count`,
  total: `Total`,
  all: `All`,
  adjustment_detail: `Adjustment Detail`,
  adjustment_type: `Adjustment Type`,
  game_type: `Game Type`,
  adjustment_types: `Adjustment Types`,
  items: `Items`,
  account_statement: `Account Statement`,
  player_id: `Player ID`,
  debit: `Debit`,
  credit: `Credit`,
  before_balance: `Before Balance`,
  after_balance: `After Balance`,
  request_id: `Request ID`,
  tip_report: `Tip Report`,
  no_data: `No Data`,
  total_tip: `Total Tip`,
  products: `Products`,
  push_message: `Push Message`,
  add_product: `Add Product`,
  product_name: 'Product Name',
  search: 'Search',
  is_active: 'Is Active',
  permission: 'Permission',
  x_is_required: '{{x}} is required',
  please_login_to_complete: 'Please log in to complete joining the group or',
  click_to_cancel: 'click here to cancel',
  scan_qr: 'Scan QR Code',
  password: 'Password',
  min_char_is_x: 'Minimum character is {{x}}',
  camera_access_denied: 'Camera access has been denied. Please check your browser settings.',
  camera_not_available: 'Camera access is not available on this device.',
  dashboard: 'Dashboard',
  points: 'Points',
  lifetime: 'Lifetime',
  claim_chips: 'Claim Chips',
  would_like_to_claim_x_chips: 'Would you like to claim your {{x}} chips now?',
  chips_rejected: 'Chips Rejected!',
  chips_added: 'Chips Added!',
  accept: 'Accept',
  reject: 'Reject',
  win_lose: 'W/L',
  my_groups: 'My Groups',
  select_country_code: 'Select Country Code',
  yes: 'Yes',
  no: 'no',
  my_transactions: 'My Transactions',
  event_ended: 'Event Ended',
  country_code: 'Country Code',
  last_updated: 'Last Updated',
  edit_profile: 'Edit Profile',
  accepted: 'Accepted',
  rejected: 'Rejected',
  would_like_to_claim_chips: 'Would you like to claim your chips?',
  x_success: '{{x}} Success!',
  chips_available: 'Chips Available!',
  no_active_group: 'No Active Group',
  seems_no_active_group: 'Seems like you have no active group, would you like to scan a Group QRCode?',
  already_in_group: "You already have an active group! You can't join another group if you have an active group",
  total_win_lose: 'Total W/L',
  overall_win_lose_turnover: 'Overall W/L and Turnover',
  view_overall_win_lose: 'View Overall W/L',
  turnover: 'Turnover',
  home: 'Home',
  group: 'Group',
  current_points: 'Current Points',
  lifetime_points: 'Lifetime Points',
  insurance: 'Insurance',
  request_otp: 'Request OTP',
  invalid_x: 'Invalid {{x}}',
  resend_otp_in_x: 'Resend OTP in {{x}}',
  resend_otp: 'Resend OTP',
  otp: 'OTP',
  otp_code: 'OTP Code',
  otp_sent_please_input: 'OTP sent! Please input the code to proceed',
  forgot_password: 'Forgot Password',
  please_enter_phonenumber_associated:
    'Please enter the phonenumber associated with your account and we will send you a code to verify and reset your password',
  back: 'Back',
  no_group_or_agent: 'No Group or Agent Selected!',
  // Translate to other language
};

export default en;
